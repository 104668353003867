<template>
    <v-container id="newsLetter" fluid>
        <v-form ref="form" v-model="valid">
            <v-row class="newsLetterTitle" v-if="title">
                {{title}}
            </v-row>
            <v-row class="newsLetterSubtitle" v-if="subTitle">
                {{subTitle}}
            </v-row>            
            <v-row class="newsLetterContent">
                <v-text-field id="newsLetterEmail"
                    v-model="email"
                    :disabled="disabled"
                    :label="label"
                    :placeholder="placeholder"
                    :dense="normalizedOptions.textField.dense"
                    :clearable="normalizedOptions.textField.clearable"
                    :hideDetails="normalizedOptions.textField.hideDetails"
                    :solo="normalizedOptions.textField.solo"                 
                    :prependIcon="normalizedOptions.textField.prependIcon"
                    :prependInnerIcon="normalizedOptions.textField.prependInnerIcon"
                    :appendIcon="normalizedOptions.textField.appendIcon"
                    :appendOuterIcon="normalizedOptions.textField.appendOuterIcon"
                    :rules="[checkEmail]">
                </v-text-field>
                <v-btn :disabled="!valid || disabled || !email || email.length < 1"
                        class="newsLetterButton"
                        @click="onClickButton">
                    <v-icon v-if="normalizedOptions.button.icon" left>{{normalizedOptions.button.icon}}</v-icon>                             
                    <span class="white--text">{{ $t("message.newsletter_button") }}</span>
                </v-btn>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
export default {
    name: 'newsLetter',
    data: function () {
        return {
            email: null,
            normalizedOptions: {
                textField: {
                    dense: false,
                    clearable: false,
                    hideDetails: 'auto',
                    solo: false,
                    prependIcon: null,
                    prependInnerIcon: null,
                    appendIcon: null,
                    appendOuterIcon: null
                },
                button: {
                    icon: null
                }
            },
            valid: false
        };
    },
    props: {
        title: null,
        subTitle: null,
        disabled: {
            type: Boolean,
            default: false
        },
        label: null,
        placeholder: null,
        options: null
    },
    methods: {
      checkEmail: function(value) {
          if (value == "")
            return true;
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("message.newsletter_check_mail")
      },
      onClickButton: function() {
          this.$emit('news-letter-register', this.email);
      }
    },
    created: function() {
      if (!this.options){
        return;
      }

      this.normalizedOptions = Object.assign(this.normalizedOptions, this.options);
    }
  }
</script>
